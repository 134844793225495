/** @format */
@import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";
.App {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #ececec;
}

@primary-color: #20A7C9;