/** @format */

@import "normalize.css";
@import "@blueprintjs/core/lib/css/blueprint.css";
@import "@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "@blueprintjs/table/lib/css/table.css";
@import "ag-grid-community/styles/ag-grid.css"; 
@import "ag-grid-community/styles/ag-theme-alpine.css"; 
@import "semantic-ui-css/semantic.css";
@import "react-grid-layout/css/styles.css";
@import "handsontable/dist/handsontable.full.min.css";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}
